import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PixSelect\n  ...attributes\n  @options={{this.levelOptions}}\n  @onChange={{@onChange}}\n  @value={{@value}}\n  @id={{@id}}\n  @label={{@label}}\n  @screenReaderOnly={{true}}\n  @hideDefaultOption={{true}}\n/>", {"contents":"<PixSelect\n  ...attributes\n  @options={{this.levelOptions}}\n  @onChange={{@onChange}}\n  @value={{@value}}\n  @id={{@id}}\n  @label={{@label}}\n  @screenReaderOnly={{true}}\n  @hideDefaultOption={{true}}\n/>","moduleName":"pix-admin/components/stages/stage-level-select.hbs","parseOptions":{"srcName":"pix-admin/components/stages/stage-level-select.hbs"}});
import Component from '@glimmer/component';
import range from 'lodash/range';

export default class StageLevelSelect extends Component {
  get levelOptions() {
    return range(this.args.maxLevel + 1).map((level) => ({
      value: level,
      label: level,
    }));
  }
}
